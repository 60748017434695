$(() => {
  $(
    [
      '.wpforms-field-text',
      '.wpforms-field-email',
      '.wpforms-field-tel',
      '.wpforms-field-url',
      '.wpforms-field-textarea',
    ].join(','),
  )
    .addClass('wpforms-with-movable-label')
    .find('input, textarea')
    .on('change', function () {
      $(this)
        .closest('.wpforms-field')
        .toggleClass('wpforms-not-empty', $(this).val().length > 0)
    })
})
